/*********************** GENERAL STYLES **********************/

.arrow {
  width: 1em;
}

.close {
  width: 15px;
}

.form_container_main {
  width : 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.basic_message {
  background-color: rgba(20,125,255,0.25);
  color: rgb(100,125,255);
}

.success_message {
  background-color: rgba(0,255,56,0.25);
  color: green;
}

.error_message {
  background-color: rgba(255,0,0,0.3);
  color: red;
}

h2 {
    font-size: large;
}

/*********************** STUDENTS PAGE ***********************/

.students_main {
  max-width: 1200px;
  min-height: 100vh;
  border: 1px solid black;
  margin: 0 auto;
}

.header {
  width: 100%;
  background-color: #f2f2f2;
  height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header_text {
  /*font-size: 2em;*/
  letter-spacing: 3px;
  margin-left: 15px;
}

.past {
  color: #ff4d4d;
}

.active {
  color: #00e600;
}

.future {
  color: #66b3ff;
}

/*********************** BRIEF CONTAINER ***********************/

.brief_container {
  padding: .5em 1em;
  border: 1px solid gray;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brief_container_left {
  width: 2em;
  display: flex;
  align-items: center;
}

.brief_container_right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.brief_right_inside {
  width: 33%;
  display: flex;
  align-items: center;
}

.brief_right_inside p{
  margin-left: 40px;
}

.frozen {
 /*font-size: 1.5em;*/
 color: red;
}

.oneOnOne {
  margin-left: 30px;
}

/*********************** DETAILS CONTAINER ***********************/

.details_container {
  width: 100%;
  border: 1px solid gray;
  display: flex;
  flex-direction: row;
}

.details_container_left {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details_container_right {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.details_right_inside {
/*  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;*/
}

.details_bottom_right {
 width: 60%;
 border: 1px solid black;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 padding-bottom: 1em;
}

.details_bottom_left {
  overflow-y: scroll;
  padding-bottom: 1em;
}

.details_container_bottom {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
}

/*********************** CALENDAR COMPONENT ***********************/

.bottom_row {
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.square_button {
 width: 20px;
 height: 20px;
 border: 1px solid black;
 border-radius: 5px;
 display: flex;
 justify-content: space-around;
 margin-left: 10px;
}

.button_container {
 display: flex;
}

.freeze_button {
  margin-top: 5%;
  outline: none;
  color: white;
  letter-spacing: 4px;
  font-weight: 700;
  padding: .5em 1em;

}

.update_button {
  margin-top: 5%;
  outline: none;
  color: white;
  letter-spacing: 4px;
  font-weight: 700;
  padding: .5em 1em;
}

.cl_upd {
  background-color: #A88FFF;
}
.cl_can {
  background-color: #FF4040;
}
.cl_frz {
  background-color: #4d94ff;
}
/**************************** NOTES COMPONENT ***************************/

.details_bottom_left {
 width: 50%;
 border: 1px solid black;
 flex-direction: column;
}

.note {
  width: 90%;
  margin: 2% 5%;
  min-height: 50px;
  background-color: #cce6ff;
}

.note_header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

textarea { 
  resize: none;
  width: 90%;
  margin: 1em;
  min-height: 100px;
}

.note_button {
  margin: 0 auto;
  display: block;
  outline: none;
  background-color: black;
  color: white;
  letter-spacing: 4px;
  font-weight: 700;
  padding: .5em 1em;
}

.x_container {
  width: 90%;
  margin: 0 5%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.details_bottom_header {
  width : 90%;
  margin-left: 5%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

/**************************** CREATE STUDENT COMPONENT ************************************/

.create_student_main {
  width : 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_container {
  width: 50%;
  border: 0.5px solid gray;
  display: flex;
  flex-direction: column;
}

.form_message {
  width: 100%;
  height: 60px;
  border-bottom: 0.5px solid gray;
  display: flex;
  justify-content: space-around;
  letter-spacing: 2px;
}

.form_row_single {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.form_row_single input{
  width: 70%;
  margin-left: 5%;
}

.form_row_single_left{
  width: 20%;
  display: flex;
  justify-content: center;
}

.form_row_single_right{
  width: 80%; 
}

.form_row_multi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.form_button {
 width: 50%;
 margin: 5% 0;
 outline: none;
 background-color: black;
 color: white;
 letter-spacing: 4px;
 font-weight: 700;

}
/**************************** LOGIN COMPONENT ********************************/

.login_form {
  width: 30%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.login_input {
  width: 50%;
  margin-bottom: 5%;
}

.login_button {
  width: 50%;
}

.login_message {
  width: 30%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/******************************** SEND EMAIL ******************************/
/* USING SOME STYLES FROM CREATE STUDENT COMPONENT*/



































